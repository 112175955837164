export default class Cookies {

	constructor() {
		this.instance = self;
	}

	static getCookie(name, value) {
		var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    	return v ? v[2] : null;
	}

	static setCookie(name, value, days) {
		var d = new Date;
	    d.setTime(d.getTime() + 24*60*60*1000*days);
	    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
	}

	static deleteCookie(name) {
		Cookies.setCookie(name, '', -1);
	}
}