import Cookies from "./library/Cookies";

export default class CookiesPopup {
    constructor() {
        this.instance = self;
    }

    static init() {
        const self = new CookiesPopup();
        self.attachEvents();
    }

    attachEvents() {
        const self = this;

        if (Cookies.getCookie("privacy_settings") == null) {
            self.openCookiesPopup();
        }

        window.site.closeCookiesPopup = function() {
            self.closeCookiesPopup();
        };

        // Custom success script (october one can be tempremental)
        $(document).on("ajaxSuccess", "[data-success-script]", function(
            event,
            context,
            statusText,
            jqXHR
        ) {
            var $form = $(this);

            eval($form.data("success-script"));
        });

        $(document).on("click", ".js-open-cookies-popup", function(e) {
            e.preventDefault();
            self.openCookiesPopup();
        });

        $(document).on("click", ".js-toggle-cookies-accordion", function(e) {
            e.preventDefault();
            self.toggleAccordion($(this));
        });

        $(document).on("click", ".js-toggle-cookies-show-more", function(e) {
            e.preventDefault();
            self.toggleShowMore($(this));
        });

        $(document).on(
            "change",
            ".js-toggle-all-accordion-section-checkboxes",
            function() {
                self.toggleAllAccordionSectionCheckboxes($(this));
            }
        );

        $(document).on("click", ".js-cookies-accept-all", function(e) {
            e.preventDefault();
            self.acceptAllCookies();
        });
    }

    openCookiesPopup() {
        $(".cookies-popup").addClass("is-open");
    }

    closeCookiesPopup() {
        $(".cookies-popup").removeClass("is-open");
    }

    toggleAccordion($link) {
        const self = this;

        let $accordion = $link.parents(".cookies-popup-accordion"),
            $content = $link
                .parents(".cookies-popup-accordion-section-title")
                .next(".cookies-popup-accordion-content");

        if ($content.is(":hidden")) {
            $content.slideDown(200);
            $link.text("Hide details");
        } else {
            $content.slideUp(200);
            $link.text("Show details");
        }
    }

    toggleShowMore($link) {
        const self = this;

        let $accordion = $link.parents(".cookies-popup-content-more"),
            $content = $link.next(".cookies-popup-content-more-content");

        if ($content.is(":hidden")) {
            $content.slideDown(200);
            $link.text("Hide more details");
        } else {
            $content.slideUp(200);
            $link.text("Show more details");
        }
    }

    toggleAllAccordionSectionCheckboxes($checkbox) {
        const self = this,
            $section = $checkbox.parents(".cookies-popup-accordion-section"),
            $checkboxes = $section.find(
                'input[type="checkbox"]:not(.js-toggle-all-accordion-section-checkboxes)'
            );

        if ($checkbox.is(":checked")) {
            $checkboxes.prop("checked", true);
        } else {
            $checkboxes.prop("checked", false);
        }
    }

    acceptAllCookies() {
        const self = this;

        $(".js-toggle-all-accordion-section-checkboxes").each(function() {
            $(this).prop("checked", true);
            self.toggleAllAccordionSectionCheckboxes($(this));
        });

        $(".js-cookies-popup-form").submit();
    }
}
