import * as utils from "./util";

export default class ToggleableFormBar {

    constructor(elem, options){
        this.elem    = elem;
        this.options = options;
    };

    init() {

        const elems = document.querySelectorAll(this.elem);

        if(elems.length) {
            elems.forEach((elem) => {

                const openTriggerSelector  = this.options.openTriggerElem || '.open-trigger',
                      closeTriggerSelector = this.options.closeTriggerElem || '.close-trigger';


                const openTriggerElem = elem.querySelector(openTriggerSelector);

                (openTriggerElem) && openTriggerElem.addEventListener('click', (e) => {
                    e.preventDefault();
                    !elem.classList.contains('open') ? elem.classList.add('open') :
                        elem.querySelector('form').submit();
                });


                const closeTriggerElem = elem.querySelector(closeTriggerSelector);

                (closeTriggerElem) && closeTriggerElem.addEventListener('click', (e) => {
                    e.preventDefault();
                    elem.classList.contains('open') && elem.classList.remove('open');
                });
            });
        }
    }
}