import $ from "jquery";

import "bootstrap";

import Choices from "choices.js";
import MobileMenu from "./mobileMenu";
import DesktopMenu from "./desktopMenu";
import ToggleableFormBar from "./toggleable-form-bar";
import mapStyle from "./mapStyle";
import CookiesPopup from "./CookiesPopup";

import * as utils from "./util";

$(() => {
    CookiesPopup.init();
});

class App {
    static init() {
        document.addEventListener("DOMContentLoaded", function() {
            App.initMobileNav();
            App.initDropdowns();
            App.initFixedHeader();
            App.initToggleableFormBar();
            App.toggleButtons();
            App.addAnchorToParent();
            App.detectBackButton();
            App.shareButtons();
        });
    }

    static shareButtons() {
        let addThisClick = $(".add-this-click");

        addThisClick.on("click", function(event) {
            event.preventDefault();

            let element = $(this);
            let addThisToolbox = $("#addthis_inline_share_buttons");
            let clicked = element.data("link");

            addThisToolbox.find("." + clicked)[0].click();
        });
    }

    static addAnchorToParent = () => {
        const parentDivs = document.querySelectorAll(
            ".image-content-grid__item--link"
        );

        parentDivs.forEach(function(parentDiv) {
            parentDiv.addEventListener("click", function(event) {
                window.location = parentDiv
                    .querySelector("a")
                    .getAttribute("href");
            });
        });
    };

    initMap = () => {
        const map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 54.622808, lng: 0.7686881 },
            zoom: 6,
            disableDefaultUI: true,
            styles: mapStyle
        });

        const type = "info";
        const iconBase = "/themes/site/assets/images/";
        const icons = {
            info: {
                icon: iconBase + "map-pin.png"
            }
        };
        const element = document.querySelectorAll(".office-location");

        element.forEach(function(elm) {
            let contentString =
                '<div id="content">' +
                '<div id="firstHeading" class="large-body-font my-0">' +
                elm.innerHTML +
                "</div>" +
                "</div>";

            const coords = {
                lat: parseFloat(elm.dataset.lat),
                lng: parseFloat(elm.dataset.long)
            };

            let marker = new google.maps.Marker({
                position: coords,
                icon: icons[type].icon,
                map: map
            });

            let infowindow = new google.maps.InfoWindow({
                content: contentString
            });

            marker.addListener("click", function() {
                infowindow.open(map, marker);
            });
        });
    };

    static toggleButtons = () => {
        const buttonGroups = document.querySelectorAll(".expertise-filter");

        buttonGroups.forEach(function(buttonGroup) {
            const hiddenInput = buttonGroup.querySelector("#buttonSelect");
            var childButtons = buttonGroup.querySelectorAll("button");

            childButtons.forEach(function(childButton) {
                childButton.addEventListener("click", function(event) {
                    childButtons.forEach(function(button) {
                        if (button === event.target) {
                            button.classList.add("btn-primary");
                            button.classList.remove("btn-primary-inverse");

                            let buttonValue = button.getAttribute("data-list");
                            hiddenInput.setAttribute("value", buttonValue);
                        } else {
                            button.classList.remove("btn-primary");
                            button.classList.add("btn-primary-inverse");
                        }
                    });
                });
            });
        });
    };

    static initFixedHeader = () => {
        const topOffset = 100;

        window.onload = function() {
            App.checkPagePosition(topOffset);
        };

        window.addEventListener("scroll", () => {
            App.checkPagePosition(topOffset);
        });

        window.addEventListener("load", event => {
            if (window.location.hash) {
                var header = document.getElementsByClassName("fixed-header")[0],
                    headerHeight = header.offsetHeight;

                window.scrollBy(0, -headerHeight);
            }
        });
    };

    static checkPagePosition(offset) {
        const scrollBreakPoint =
            utils.offset(document.getElementById("scroll-anchor")).top - offset;

        if (window.scrollY > scrollBreakPoint && window.scrollY > 0) {
            !document.body.classList.contains("scrolling") &&
                document.body.classList.add("scrolling");
        } else {
            document.body.classList.contains("scrolling") &&
                document.body.classList.remove("scrolling");
        }
    }

    static initDropdowns = () => {
        const dropdowns = document.querySelectorAll(".custom-dropdown");

        dropdowns.forEach(el => {
            const dropdown = new Choices(el, {
                searchEnabled: false,
                itemSelectText: "",
                position: "top",
                shouldSort: false
            });
        });
    };

    static initMobileNav = () => {
        const mobileNavElement = document.getElementById("fr-kelly-menu"),
            mobileMenu = new MobileMenu(mobileNavElement, {});

        const desktopNavElement = document.getElementById("desktop-nav"),
            desktopNav = new DesktopMenu(desktopNavElement, {});

        desktopNav.init();
        mobileMenu.init();
    };

    static initToggleableFormBar = () => {
        const toggleableFormBar = new ToggleableFormBar(".search-toolbar", {
            openTriggerElem: ".open-trigger",
            closeTriggerElem: ".close-trigger"
        });

        toggleableFormBar.init();
    };

    paginate = relativeUrl => {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get("page")
            ? parseInt(urlParams.get("page")) + 1
            : 2;
        const paginationButton = document.getElementById("pageNumber");

        if (paginationButton) {
            paginationButton.value = page;
        }

        urlParams.set("page", page);

        window.history.pushState(
            "",
            "",
            relativeUrl + "?" + urlParams.toString()
        );
    };

    static detectBackButton = () => {
        // check if page has pagination
        const pagination = document.getElementById("pagination");

        if (pagination) {
            window.addEventListener("popstate", function() {
                window.location.reload();
            });
        }
    };
}

// Expose jQuery globally
window.jQuery = window.$ = global.$ = global.jQuery = $;

// Instantiate App
let APP = new App();

// Run initialisation
App.init();

// Expose App instance globally
global.APP = APP;
