export default class DesktopMenu {

    constructor(elem, options){
        this.elem = elem;
    };

    deactivateDropdownLinks = () => {

        this.dropdownLinks.forEach((el) => {
            el.parentElement.classList.remove('active');
        });

    };

    init() {
        this.dropdownLinks = this.elem.querySelectorAll('.dropdown-link');

        this.dropdownLinks.forEach((dropdownLink) => {

            dropdownLink.parentNode.querySelectorAll('.sub-link').forEach((subLink) => {
                const menuHref = subLink.href.split('#')[1];

                subLink.addEventListener('click', (e) => {
                    e.preventDefault();

                    dropdownLink.parentNode.querySelectorAll('.sub-link').forEach((sl) => sl.classList.remove('active'));
                    subLink.classList.add('active');

                    document.querySelectorAll('.sub-menu').forEach((subMenu) => {
                        subMenu.classList.remove('active');
                    });

                    const menu = document.getElementById(menuHref);

                    menu.classList.add('active');
                });
            });

            dropdownLink.addEventListener('click', (e) => {
                e.preventDefault();

                const parent = dropdownLink.parentElement;

                if(!parent.classList.contains('active')) {
                    parent.classList.add('active');
                    document.body.classList.add('desktop-nav-open');
                }else{
                    this.deactivateDropdownLinks();
                    document.body.classList.remove('desktop-nav-open');
                }
            });
        });
    };
}