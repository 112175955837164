import 'hammerjs';

export default class MobileMenu {

    constructor(elem, options){
        this.elem = elem;

        this.menuVisible = false;

        this.primaryNav   = this.elem.querySelectorAll('.primary-nav').item(0);
        this.secondaryNav = this.elem.querySelectorAll('.sub-nav').item(0);
        this.menuToggle   = document.getElementById('menu-toggle');
        this.displayingNav = this.secondaryNav;
    };

    init() {

        let primaryNav = this.primaryNav;
        let secondaryNav = this.secondaryNav;

        this.primaryNav.querySelectorAll('.subnav-link').forEach((el) => el.addEventListener('click', function(e){
            e.preventDefault();

            secondaryNav.querySelectorAll('.sub-nav-list').forEach((subLink) => {
                let parentLinkRef = el.getAttribute('data-num');
                let subLinkRef = subLink.getAttribute('data-num');


                if (parentLinkRef === subLinkRef) {
                    primaryNav.classList.remove('active');
                    subLink.classList.toggle('d-none');
                    subLink.classList.add('active');
                    secondaryNav.classList.add('active');
                }
            });
        }));

        this.secondaryNav.querySelector('.back-btn').addEventListener('click', (e) => {
            e.preventDefault();
            this.displayingNav = this.secondaryNav.querySelector('.sub-nav-list.active');

            this.primaryNav.classList.add('active');
            this.secondaryNav.classList.remove('active');
            this.displayingNav.classList.toggle('d-none');
            this.displayingNav.classList.remove('active');
        });


        this.menuToggle.addEventListener('click', (e) => {

            if(this.menuToggle.classList.contains('open')) {
                this.menuToggle.classList.remove('open');
                document.body.classList.remove('mobile-nav-open');

                this.displayingNav = this.secondaryNav.querySelector('.sub-nav-list');

                if (this.displayingNav) {
                    this.displayingNav.classList.add('d-none');
                    this.displayingNav.classList.remove('active');
                }

            }else{
                this.menuToggle.classList.add('open');
                document.body.classList.add('mobile-nav-open');
            }

            if(this.isMobile()) {
                this.menuVisible = !this.menuVisible;
                this.menuVisible ? this.showMobileNav() : this.hideMobileNav();
                document.body.classList.add('mobile');
            }
        });

        const hammerTime = new Hammer(this.secondaryNav);

        hammerTime.on('swiperight', (ev) => {
            this.displayingNav = this.secondaryNav.querySelector('.sub-nav-list.active');

            if(this.secondaryNav.classList.contains('active')) {
                this.primaryNav.classList.add('active');
                this.secondaryNav.classList.remove('active');
                this.displayingNav.classList.toggle('d-none');
                this.displayingNav.classList.remove('active');
            }
        });
    };

    isMobile = () => {
        return window.innerWidth < 1200;
    };


    showMobileNav = () => {
        this.elem.classList.add('active');
    };

    hideMobileNav = () => {
        this.elem.classList.remove('active');

        this.primaryNav.classList.add('active');
        this.secondaryNav.classList.remove('active');

    };
}
